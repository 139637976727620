import * as React from 'react'
import { Link } from "gatsby"
import Layout from '../components/layout'

const InstallerThankYou = () => {
    return (
        <Layout>
        <div className="min-h-screen bg-slate-100">
            <div className="mx-auto max-w-3xl py-24 px-4 sm:px-10 sm:py-52 lg:px-12">
                <div className="flex flex-col">
                    <h3 className="text-center">We're on it!</h3>
                    <p className="text-center">
                        You can expect an email in the next 12-24 hours with recommendations on the best heat pump installers in your area. 
                        In the meantime, check out <Link to="/heating-and-cooling"> our guides on heat pumps.</Link> 
                    </p>
                </div>
            </div>
        </div>
        </Layout>
    )
}

export default InstallerThankYou